import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Faq from '../components/Faq/index'
import PageTitle from '../components/Blocks/PageTitle'
import { Helmet } from 'react-helmet'
import { Plus, Minus, CaretUp, CaretDown } from 'phosphor-react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Virtual } from 'swiper'
import { Autoplay, Swiper as Core, Navigation, Pagination } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const AccordionItem = ({ ariaExpanded, item, index, onClick }) => {
  Core.use([Autoplay, Navigation, Pagination])

  return (
    <div className="accordionItem w-full mb-10" key={item.question}>
      <dt>
        <button
          aria-expanded={ariaExpanded}
          aria-controls={`faq${index + 1}_desc`}
          data-qa="faq__question-button"
          className={`text-white group-hover:text-brand-green py-6 flex justify-center items-center w-full transition-all relative text-2xl bg-brand-blue`}
          onClick={onClick}
          type="button"
        >
          {item.title}
          <div className="absolute right-8">
            {ariaExpanded === 'true' ? <CaretUp /> : <CaretDown />}
          </div>
        </button>
      </dt>

      {ariaExpanded === 'true' &&
        item.items.map(({ node }) => (
          <div key={node.id} className="p-4 text-center pb-10 border-b">
            <h3>{node.title}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: node.content }}
              className="mb-10"
            />
            {node.communityStoryImages?.images.length > 1 && (
              <Swiper
                navigation
                pagination
                className="mySwiper overflow-auto"
                loop={true}
                modules={[Virtual]}
                allowClick={true}
                shouldSwiperUpdate
                style={{ overflowY: 'auto' }}
              >
                {node.communityStoryImages.images.map(img => (
                  <SwiperSlide data-history="1" key={img.imageAltText}>
                    <img
                      src={img.image.mediaItemUrl}
                      alt={img.imageAltText}
                      className="mx-auto max-h-96 h-full w-full object-contain"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            {node.communityStoryImages?.images.length == 1 &&
              node.communityStoryImages.images.map(img => (
                <img
                  src={img.image.mediaItemUrl}
                  alt={img.imageAltText}
                  className="mx-auto max-h-96 h-full w-full object-contain"
                />
              ))}
          </div>
        ))}
    </div>
  )
}

const Accordion = ({ items, color = 'brand-blue' }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleClick = index => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  const renderedItems = items.map((item, index) => {
    const showDescription = index === activeIndex ? 'show' : 'hidden'
    const fontWeightBold = index === activeIndex ? 'font-weight-bold' : ''
    const ariaExpanded = index === activeIndex ? 'true' : 'false'
    return (
      <AccordionItem
        showDescription={showDescription}
        fontWeightBold={fontWeightBold}
        ariaExpanded={ariaExpanded}
        item={item}
        index={index}
        onClick={() => handleClick(index)}
        // eslint-disable-next-line react/no-array-index-key
        key={`accordion-item-${index}`}
        color={color}
        textColor={color === 'brand-blue' ? 'black' : color}
      />
    )
  })

  return (
    <div className="mx-auto px-8 md:px-20 max-w-screen-xl my-10">
      <dl className="faq__list">{renderedItems}</dl>
    </div>
  )
}

const CommunityRelationsTemplate = ({
  title,
  content,
  faq,
  communityStories,
  page,
}) => {
  let accordionItems = communityStories.group
    ?.map(year => ({
      title: year.fieldValue,
      items: year.edges,
    }))
    .reverse()

  const { communityRelationsCharities } = page

  return (
    <div className="w-full">
      <div
        className={'transition-all page-content'}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <Accordion items={accordionItems} />
      <div className="my-20">
        <h3 className="text-center mb-10">
          {communityRelationsCharities.sectionTitle}
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {communityRelationsCharities.name.map(({ name }) => (
            <p className="text-center mb-4">{name}</p>
          ))}
        </div>
      </div>
      {faq?.title && <Faq title={faq?.title} items={faq?.faqs} />}
    </div>
  )
}

const Page = ({ data, pageContext }) => {
  const { wpPage: page, allWpCommunityStory: communityStories } = data
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <Helmet>
        <script type="application/ld+json">
          {`[{"@context":"https://schema.org","@graph":[{"@context":"https://schema.org","@type":"SiteNavigationElement","@id":"https://www.dmautoleasing.com/#Main Menu","name":"Home","url":"https://www.dmautoleasing.com/"}]},{"@context":"https://schema.org","@type":"Review","@id":"https://www.dmautoleasing.com/about/community-relations/#Review","itemReviewed":{"@type":"LocalBusiness","image":{"@type":"ImageObject","url":"https://www.dmautoleasing.com/wp-content/uploads/2020/10/bg-community-header-e1602084385206.png","width":1569,"height":682}},"name":"D&amp;M Auto Leasing","author":{"@type":"Person","name":"Darren Tractorbeam"},"image":[{"@type":"ImageObject","@id":"https://www.dmautoleasing.com/about/community-relations/#primaryimage","url":"https://www.dmautoleasing.com/wp-content/uploads/2020/10/bg-community-header-e1602084385206.png","width":"1569","height":"682"},{"@type":"ImageObject","url":"https://www.dmautoleasing.com/wp-content/uploads/2020/10/bg-community-header-e1602084385206-1200x900.png","width":"1200","height":"900"},{"@type":"ImageObject","url":"https://www.dmautoleasing.com/wp-content/uploads/2020/10/bg-community-header-e1602084385206-1200x675.png","width":"1200","height":"675"}]}]`}
        </script>
      </Helmet>
      <CommunityRelationsTemplate
        title={page.title}
        content={page.content}
        faq={page.faq}
        page={page}
        communityStories={communityStories}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query CommunityRelations($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      communityRelationsCharities {
        sectionTitle
        name {
          name
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
    }
    allWpCommunityStory {
      group(field: communityStoryOptions___year) {
        fieldValue
        edges {
          node {
            id
            title
            content
            communityStoryImages {
              images {
                image {
                  id
                  mediaItemUrl
                }
                imageAltText
              }
            }
          }
        }
      }
    }
  }
`
